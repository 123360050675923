<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Products</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Products"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="green lighten-4 green--text"
                  v-on="on"
                  class="mr-2"
                  @click="$refs.productDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archive Products</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="products"
        v-sortable-data-table
        @sorted="saveOrder"
        :disable-pagination="true"
        :hide-default-footer="true"
        class="mt-4"
        no-data-text="No Products found"
      >
        <template v-slot:item.sort="{ item }">
          <v-icon small class="page__grab-icon">mdi-arrow-all</v-icon>
        </template>

        <template v-slot:item.price="{ item }">
          £{{ formatPrice(item.price) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.productDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <product-dialog ref="productDialog" />
    <v-dialog v-model="deleteProduct.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Product</v-card-title>
        <v-card-text
          >Are you sure you want to archive this product form?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteProduct.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductDialog from "./components/ProductDialog.vue";
import Sortable from "sortablejs";

export default {
  components: {
    ProductDialog,
  },

  data() {
    return {
      deleteProduct: {
        dialog: false,
        product: {},
        loading: false,
      },
      groups: [],
      tableHeaders: [
        { text: "", value: "sort" },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Products",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    products() {
      let products = this.$store.getters["petroshops/productsStore/all"];

      if (this.searchTerm !== "") {
        products = products.filter((p) => {
          const name = p.name.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm);
        });
      }

      return products;
    },
  },

  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },

  methods: {
    saveOrder(event) {
      const movedItem = this.products.splice(event.oldIndex, 1)[0];
      this.products.splice(event.newIndex, 0, movedItem);

      const displayOrder = this.products.map((p) => {
        return p.id;
      });
      const appId = this.$route.params.id;

      this.$store.commit("showHideLoader", true);
      this.$store
        .dispatch("petroshops/productsStore/updateDisplayOrder", {
          appId,
          displayOrder,
        })
        .then(() => this.$store.commit("showHideLoader", false))
        .catch(() => this.$store.commit("showHideLoader", false));
    },

    openDelete(product) {
      this.deleteProduct.product = product;
      this.deleteProduct.dialog = true;
    },

    resetDelete() {
      this.deleteProduct.dialog = false;
      this.deleteProduct.product = {};
      this.deleteProduct.loading = false;
    },

    saveDelete() {
      this.deleteProduct.loading = true;

      this.$store
        .dispatch("petroshops/productsStore/deleteProduct", {
          appId: this.$route.params.id,
          productId: this.deleteProduct.product.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteProduct.loading = false;
        });
    },
  },
};
</script>
